import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { OrarioLavorativoService } from '../services/orario-lavorativo/orario-lavorativo.service';
import { OrarioLavorativoDTO } from '../shared/dto/orario-lavorativo/orario-lavorativo';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-orario-lavorativo',
  templateUrl: './orario-lavorativo.component.html',
  styleUrls: ['./orario-lavorativo.component.scss']
})

export class OrarioLavorativoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  giorni= this.orarioLavorativoService.giorni;
  tabellaRaggruppata: any[]=[];
  orarilavorativi: OrarioLavorativoDTO[];
  
  orarilavorativiDisplayColumns: string[];
  defaultSortField = 'collaboratore';
  defaultSortDirection = 'asc';
  vistaData: boolean = false;

  

  constructor(
    private orarioLavorativoService: OrarioLavorativoService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.orarilavorativiDisplayColumns = ['collaboratore', this.giorni[0], this.giorni[1], this.giorni[2], this.giorni[3],this.giorni[4], this.giorni[5], this.giorni[6], 'duplicate'];
      this.displayedColumns = ['id', 'collaboratore', 'giorno', 'daOre', 'aOre', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: true,
        collaboratore: null,
        giorno:null,
        collaboratoreChanged: false,
        sortField: 'collaboratore',
        sortDirection: 'ASC',
        pageNumber: 0,
        pageSize: 50,
        length: 0,
      }
    } 


  list() {
    this.orarioLavorativoService.orarioLavorativoList(
      this.parameters.collaboratore,
      this.parameters.giorno,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
    ).subscribe(
      (res: ResponseQueryByCriteria<OrarioLavorativoDTO>) => {
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
        this.tabellaRaggruppata=this.ragruppaTabella(this.parameters.dataSource);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidOrarioLavorativoException_ORARIO_LAVORATIVO_NOT_FOUND")  {
          this.snackBar.open("Attenzione! L'orario lavorativo di questo collaboratore e per questo giorno non esiste ", null, { duration: 4500 });
        }
      } 
    )
  }


  ngOnInit() {

    super.ngOnInit();
    if(this.parameters.collaboratore=="null" || this.parameters.collaboratore==null){
      this.parameters.collaboratore=this.collaboratori;
    }
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }

    this.collaboratoreService.collaboratoreNoAmList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );
  }
 
  ragruppaTabella(orariLavorativi: OrarioLavorativoDTO[]): any{ 
    
    const map = new Map(); 
    orariLavorativi.forEach((orario) => {
     map.set(orario.collaboratore.id+' '+orario.collaboratore.nome+' '+orario.collaboratore.cognome, orariLavorativi.filter(orarioFiltrato => orarioFiltrato.collaboratore.codiceCollaboratore == orario.collaboratore.codiceCollaboratore).map(orarioLF => {      

      let key = orarioLF.giorno;
      let obj = {
        [key]: {
            idOrario: orarioLF.id,
            daOre: orarioLF.dalleOre,
            aOre: orarioLF.alleOre
        }
      };
        return obj;
      }));
    });
    return Array.from(map);
};

splitKeyCollaboratore(collaboratoreKey: string, tipo?: string): string{
  
  let splitted= collaboratoreKey.split(" ");
  if(tipo=="id"){
    return splitted[0];
  }
  else{
    return splitted[1]+" "+splitted[2];
  }
}

navigateToFiglio(orario: any) {
  const collaboratoreId = this.splitKeyCollaboratore(orario[0], "id");
  this.router.navigate([`/orario-lavorativo/duplicate`,collaboratoreId]);
}

translateDayToIta(giornotradotto:string){
  return this.orarioLavorativoService.translateDayToIta(giornotradotto);
}
  onTabChange(event: MatTabChangeEvent) {
    event.index != 0 ? this.vistaData = true : this.vistaData = false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}



