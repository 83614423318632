import { HttpErrorResponse } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { TenantDTO } from '../../shared/dto/domain/tenant';
import { MovimentoDTO } from '../../shared/dto/credito/movimento';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QrcodeService } from '../../services/qrcode.service';
import { CreditoService } from '../../services/credito/credito.service';
import { NavigatorService } from '../../services/navigator.service';
import { CreditoDTO } from '../../shared/dto/credito/credito';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogoDTO } from '../../shared/dto/credito/catalogo';
import { CatalogoService } from '../../services/credito/catalogo.service';
import { MovimentoService } from '../../services/credito/movimento.service';

@Component({
  selector: 'app-spesa-credito',
  templateUrl: './spesa-credito.component.html',
  styleUrls: ['./spesa-credito.component.scss']
})
export class SpesaCreditoComponent implements OnInit {

  movimento : MovimentoDTO;

  creditoId : number;

  collaboratore: CollaboratoreDTO;

  qrcode: boolean = true;

  manualSwitchEnabled: boolean = false;

  audio = new Audio();

  audioError = new Audio();
  
  catalogos : CatalogoDTO[];

  reg = new RegExp('^[0-9]*$');
  
  barcodeReading: boolean;

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  availableDevices: MediaDeviceInfo[];
    
  selectedDevice: MediaDeviceInfo;


  constructor(private navigatorService : NavigatorService, 
    private catalogoService : CatalogoService, 
    private creditoService : CreditoService, 
    private movimentoService : MovimentoService, 
    private qrcodeService : QrcodeService,
    private snackBar: MatSnackBar, 
    private router : Router
  ) {
    this.movimento = new MovimentoDTO(); 
    this.movimento.data = new Date();
    this.movimento.totaleCrediti = null;
    this.movimento.qtaProdotti = 1;
    this.collaboratore = new CollaboratoreDTO();
    this.audio.src = "../../assets/CashRegister.wav";
    this.audio.load();
    this.audioError.src = "../../assets/Error.wav";
    this.audioError.load();
    this.barcodeReading = false;
    this.selectedDevice = null;
  }

  ngOnInit() {
    this.navigatorService.tenant.subscribe(
      (res: TenantDTO) => {
        this.movimento.tenant = res;
      }
    );

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.creditoService.creditoSearchByCollaboratore(this.collaboratore.id).subscribe(
          (result: CreditoDTO) => {
          console.log("response : " + result);
          this.movimento.credito = result;
          }
        );
      }
    );

    this.catalogoService.catalogoSearchByDescrizione(
      0, 
      9999, 
      'ASC',
      'id',
      null).subscribe(
        (res: ResponseQueryByCriteria<CatalogoDTO>) => {
          console.log("response : " + res);
          this.catalogos = res.content;
        }
      );

    this.barcodeReading = false;
  }

  qrcodeReaded(qrcode : string) {
    console.log("qrcode readed : " + qrcode);

    if (this.barcodeReading) return;
    this.barcodeReading=true;
    
    if (!qrcode.startsWith("catalogo")) {
      this.audioError.play();
      this.snackBar.open("Attenzione! QR Code non valido.", null, { duration: 3000 });
      this.barcodeReading=false;
      return;
    }
    this.qrcodeService.qrcodeRead<CatalogoDTO>(qrcode).subscribe(
      (res) => {
        console.log("response : " + res.descrizione);
        this.audio.play();
        this.movimento.catalogo = res;
        this.movimento.qtaProdotti = 1;
        this.movimentoSave();
      }, (err: HttpErrorResponse) => {
        this.audioError.play();
        this.barcodeReading=false;
        if(err.status == 404 ){
          this.snackBar.open("Attenzione! QR Code non valido.", null, { duration: 3000 });
        }
      }
    );
  }

  scannerStarted(event?: any) {
    console.log("scannerStarted()");
    this.manualSwitchEnabled = true;
  }

  scannerCamerasFound() {
    navigator.mediaDevices.enumerateDevices()
    .then( (mediaDevices) => {
      this.availableDevices = [];
      console.log("Lista dei device trovati: ");
      mediaDevices.forEach( (mediaDevice : MediaDeviceInfo) => {
        if(mediaDevice.kind == 'videoinput'){
          console.log(mediaDevice)
          this.availableDevices.push(mediaDevice);
        }
      })
    });
    }

  onDeviceChange(value : MediaDeviceInfo){
    console.log("Device cambiato in: " + value.label);
    this.selectedDevice = value;
    this.scanner.restart(); 
  }

  movimentoSave() {
    var test = ""+this.movimento.qtaProdotti;
    if(this.reg.test(test)){
      if(this.movimento.qtaProdotti <= 20){
      this.movimentoService.create(this.movimento).subscribe(
        (res: MovimentoDTO) => {
          console.log("response : " + res);
          this.snackBar.open('Acquistato : ' + this.movimento.catalogo.descrizione, null, {
            duration: 3000
          });
          this.router.navigate(["/gestione-credito"]);
        });
      }else{
        this.barcodeReading=false;
        this.snackBar.open('Puoi acquistare un massimo di 20 unità ', null, {
          duration: 3000
        });
      }
    }else{
      this.barcodeReading=false;
      this.snackBar.open('Valore non valido ', null, {
        duration: 3000
      });
    }
  }

  scanQrcode() {
    this.qrcode = true;
    this.scanner.restart();
  }

  enterManually() {
    this.qrcode = false;
    this.manualSwitchEnabled = false;
    this.scanner.reset();
  }

}
