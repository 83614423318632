import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { AssenzaService } from '../services/assenza/assenza.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { NavigatorService } from '../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';

@Component({
  selector: 'app-registra-assenza-detail',
  templateUrl: './registra-assenza-detail.component.html',
  styleUrls: ['./registra-assenza-detail.component.scss']
})

export class RegistraAssenzaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  statoRichiestaValues: selectedValue[];
  tipoRichiestaValues: selectedValue[];
  assenzaId: number;
  assenza: AssenzaDTO;
  collaboratori: CollaboratoreDTO[];
  dataMax: Date;
  dataMin: Date;
  bloccaModifica: boolean;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  constructor(
    private urlService: UrlService,
    private assenzaService: AssenzaService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      statoRichiesta: new FormControl('', Validators.required),
      tipoRichiesta: new FormControl('FERIE', Validators.required),
      richiedente: new FormControl('', Validators.required),
      approvatore: new FormControl(),
      numeroDiOre: new FormControl('', [Validators.required, Validators.min(1), Validators.max(8)]),
      inizioPermesso: new FormControl('', [Validators.required]),
      descrizione: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
    this.tipoRichiestaValues = [
      { value: 'FERIE', viewValue: 'FERIE' },
      { value: 'PERMESSO', viewValue: 'PERMESSO' }
    ];
    this.statoRichiestaValues = [
      { value: 'APPROVATO', viewValue: 'APPROVATO' },
      { value: 'DA_APPROVARE', viewValue: 'DA APPROVARE' },
      { value: 'RIFIUTATO', viewValue: 'RIFIUTATO' }
    ];
  }

  ngOnInit() {
    this.assenzaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.assenzaId != 0 && this.assenzaId != null) {
      this.assenzaRead();
    } else {
      this.assenza = new AssenzaDTO();
      this.onTipoRichiestaInit(this.tipoRichiestaValues);
    }
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("statoRichiesta").setValue("DA_APPROVARE");
        this.form.get("richiedente").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
      this.urlService.previousUrl$.subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl;
      });
  }

  onTipoRichiestaChanged({ value }) {
    this.tipoRichiestaChanged(value);
  }

  onTipoRichiestaInit(value) {
    this.tipoRichiestaChanged(value);
  }

  private tipoRichiestaChanged(value) {
    console.log(value);
    if (value === 'PERMESSO') {
      if (this.dataMin && this.dataMax) {
        if (this.dataMin.getTime()==this.dataMax.getTime()) {
          this.form.get('numeroDiOre').enable();
          this.form.get('inizioPermesso').enable();
          if (this.assenza.ore == null) {
            this.form.get('numeroDiOre').setValue(1);
          } else {
            this.form.get('numeroDiOre').setValue(this.assenza.ore);
          }
        } else {
          this.form.get('numeroDiOre').disable();
          this.form.get('inizioPermesso').disable();
          this.form.get('numeroDiOre').setValue((((this.dataMax.getTime() - this.dataMin.getTime()) / (1000 * 60 * 60 * 24) ) + 1) * 8);
        }
      }
    } else {
      this.form.get('numeroDiOre').disable();
      this.form.get('inizioPermesso').disable();
      this.form.get('numeroDiOre').setValue(null);
       }
    } 
  

  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
    if (this.dataMax==null) {
      this.dataMax = this.dataMin;
      this.form.get("dataA").setValue(this.dataMax);
    }
    this.tipoRichiestaChanged(this.form.get("tipoRichiesta").value);
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
    if (this.dataMin==null) {
      this.dataMin = this.dataMax;
      this.form.get("dataDa").setValue(this.dataMin);
    }
    this.tipoRichiestaChanged(this.form.get("tipoRichiesta").value);
  }

  assenzaSave() {
    if (this.assenza.id == null) {
      this.assenzaCreate();
    } else {
      this.assenzaUpdate();
    }
  }

  assenzaCreate() {
    if (this.form.valid) {
      if (this.isJappTenantAdmin() == false) {
        this.assenza.statoRichiesta = 'DA APPROVARE';
      }
      if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
        this.navigatorService.collaboratore.subscribe(
          (res: CollaboratoreDTO) => {
            this.form.get("approvatore").setValue(res);
          }
        );
      }
      this.formToDto();
      this.assenzaService.create(this.assenza).subscribe(
        (res: AssenzaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.assenza = res;
          this.assenzaId = this.assenza.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidAssenzaException_ASSENZA_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una assenza nel periodo di date indicato.", null, { duration: 3000 });
          } else {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  assenzaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'assenza?").subscribe(result => {
        if (result) {
          if (this.isJappTenantAdmin() == true) {
            if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
              this.navigatorService.collaboratore.subscribe(
                (res: CollaboratoreDTO) => {
                  this.form.get("approvatore").setValue(res);
                }
              );
            }
          }
          this.formToDto();
          console.log(this.assenza.approvatore);
          this.assenzaService.update(this.assenza).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.assenzaRead();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidAssenzaException_ASSENZA_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una assenza nel periodo di date indicato.", null, { duration: 3000 });
              } else {
                  this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  assenzaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la assenza?").subscribe(result => {
        if (result) {
          this.formToDto();
          if(this.assenza.statoRichiesta == "APPROVATO" || this.assenza.statoRichiesta == "RIFIUTATO"){
            this.snackBar.open("Attenzione! Non puoi cancellare una richiesta già approvata o rifiutata.", null, { duration: 3000 });
            return;
          }
          this.assenzaService.delete(this.assenza.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/registra-assenza"]);
            }
          );
        }
      });
    }
  }


  assenzaRead() {
    this.assenzaService.read(this.assenzaId).subscribe(
      (res: AssenzaDTO) => {
        console.log("response : " + res);
        this.assenza = res;
        this.assenzaId = res.id;
        this.dtoToForm();
        this.onTipoRichiestaInit(this.assenza.tipoRichiesta);
        if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
          if (this.isJappTenantAdmin() == false) {
            this.bloccaModifica = true;
            this.form.get("descrizione").disable();
            this.form.get("numeroDiOre").disable();
            this.form.get("inizioPermesso").disable();
          }
        }
      }
    );
  }

  private formToDto(): void {
    this.assenza.dataDa = this.form.get("dataDa").value;
    this.assenza.dataA = this.form.get("dataA").value;
    this.assenza.approvatore = this.form.get("approvatore").value;
    this.assenza.richiedente = this.form.get("richiedente").value;
    this.assenza.statoRichiesta = this.form.get("statoRichiesta").value;
    this.assenza.tipoRichiesta = this.form.get("tipoRichiesta").value;
    this.assenza.ore = this.form.get("numeroDiOre").value;
    this.assenza.descrizione = this.form.get("descrizione").value;
    if (this.form.get("inizioPermesso").value != null) {
      this.assenza.orarioInizioPermesso = this.formatToNumberStartTimePermit(this.form.get("inizioPermesso").value);
    } else {
      this.assenza.orarioInizioPermesso = this.formatToNumberStartTimePermit("0:00");
    }
    
  }

  private formatToNumberStartTimePermit(str): number{
      var splitted = str.split(":");
      return parseInt(splitted[0]);
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.assenza.id);
    this.form.get("dataDa").setValue(this.assenza.dataDa);
    this.form.get("dataA").setValue(this.assenza.dataA);
    this.form.get("approvatore").setValue(this.assenza.approvatore);
    this.form.get("richiedente").setValue(this.assenza.richiedente);
    this.form.get("statoRichiesta").setValue(this.assenza.statoRichiesta);
    this.form.get("tipoRichiesta").setValue(this.assenza.tipoRichiesta);
    this.form.get("numeroDiOre").setValue(this.assenza.ore);
    this.form.get("descrizione").setValue(this.assenza.descrizione);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.assenza.insertDate));
    this.form.get("insertUser").setValue(this.assenza.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.assenza.updateDate));
    this.form.get("updateUser").setValue(this.assenza.updateUser);
    if (this.assenza.orarioInizioPermesso != null) {
      this.form.get("inizioPermesso").setValue(`${this.assenza.orarioInizioPermesso}:00`);
    } else {
      this.form.get("inizioPermesso").setValue(`0:00`);
    }
   
    this.dataMin = new Date(this.assenza.dataDa);
    this.dataMax = new Date(this.assenza.dataA);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

export interface selectedValue {
  value: string;
  viewValue: string;
}