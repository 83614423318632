import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { NavigatorService } from '../services/navigator.service';
import { PrenotazionePostazioneService } from '../services/prenotazionepostazione/prenotazionepostazione.service';
import { SedeService } from '../services/sede/sede.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { PrenotazionePostazioneDTO } from '../shared/dto/prenotazionepostazione/prenotazionepostazione';
import { ResponseFail } from '../shared/dto/responseFail';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { SedeDTO } from '../shared/dto/sede/sede';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { PostazioneService } from './../services/postazione/postazione.service';
import { PostazioneDTO } from './../shared/dto/postazione/postazione';
import { takeUntil } from 'rxjs/operators';

interface Rect {
  id: number, 
  height : number, 
  width: number, 
  top: any, 
  left: any, 
  discriminator: string,
  isPrenotata: boolean,
  nome?: string,
  cognome?: string
}
@Component({
  selector: 'app-prenotazionepostazione-detail',
  templateUrl: './prenotazionepostazione-detail.component.html',
  styleUrls: ['./prenotazionepostazione-detail.component.scss']
}) 

export class PrenotazionepostazioneDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  prenotazionepostazioneId: number;
  prenotazionepostazione: PrenotazionePostazioneDTO;
  collaboratori: CollaboratoreDTO[];
  sedi: SedeDTO[];
  dataMax: Date;
  dataMin: Date;
  bloccaModifica: boolean;
  sede: SedeDTO;
  numeroPostazioniPrenotabiliInSede: number;
  prenotazionePerOspite: boolean;
  private mappaPostazioniRect: Map<number, Rect> = new Map<number, Rect>();
  postazioniRect: Rect[] = [];
  filteredPostazioni: PostazioneDTO[] = [];
  private destroy$ = new Subject<boolean>();
  private postazioneTimer: any;



  inizializzaDescrizione = () => (value: PostazioneDTO | string): string => {

    if (typeof value == 'string') {
      return value;
    }
    return value.descrizione;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private prenotazionepostazioneService: PrenotazionePostazioneService,
    private collaboratoreService: CollaboratoreService,
    private postazioneService: PostazioneService,
    private sedeServices: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      richiedente: new FormControl('', Validators.required),
      note: new FormControl(''),
      sede: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
      prenotazionePerOspite: new FormControl(''),
    });
  }
  
  ngOnInit() {
    this.prenotazionepostazioneId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.prenotazionepostazioneId != 0 && this.prenotazionepostazioneId != null) {
      this.prenotazionepostazioneRead();
    } else {
      this.prenotazionepostazione = new PrenotazionePostazioneDTO();
    }
    this.navigatorService.collaboratore.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("richiedente").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );
    this.sedeServices.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: ResponseQueryByCriteria<SedeDTO>) => {
        this.sedi = res.content;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  filtraCompletaByFiltered(filteredPostazione: PostazioneDTO[]) {
    let listaCompleta = filteredPostazione;
    let flag: boolean;
    listaCompleta = listaCompleta.filter( elemOfCompleta =>{
      flag=false;
      filteredPostazione.forEach( elemOfFiltered =>{
        if(elemOfCompleta.descrizione === elemOfFiltered.descrizione)
          flag = true;
      })
      return flag;
    })
    this.filteredPostazioni = listaCompleta;
  }
  postazioneChanged(newValue:string, fromUser:boolean) {
    if (fromUser) {
      clearTimeout(this.postazioneTimer);
      this.postazioneTimer = setTimeout(() => {
        this.postazioneChanged(newValue, false);
      }, 500);
    } else {
      this.postazioneService.postazioniListByAutocomplete(
      newValue)
      .subscribe(
        (res: PostazioneDTO[])=> {
            this.filtraCompletaByFiltered(res);            
        });
      }
  }

  checkIfPrenotazioneIsNew(){
    return this.prenotazionepostazioneId !== 0;
  }

  postazioneDescrizione(postazione: PostazioneDTO): string {
    return (postazione && postazione.descrizione) ? postazione.descrizione : '';
  }

  setPostazione() {
    this.form.get("descrizione").setValue('');
    if (this.form.get("dataDa").valid && this.form.get("dataA").valid && this.form.get("sede").valid) {
  
      this.fillMapOfRect();
      
    }
  }

  fillMapOfRect(){
    this.filteredPostazioni = [];
    this.mappaPostazioniRect = new Map<number, Rect>();
    this.numeroPostazioniPrenotabiliInSede = 0;
    combineLatest([this.prenotazionepostazioneService.postazioniDisponibiliList(
      0,
      9999,
      'ASC',
      'descrizione',
      this.form.get("dataDa").value,
      this.form.get("dataA").value,
      this.form.get("id").value,
      this.form.get("sede").value,
      null
    ),
    this.prenotazionepostazioneService.situazionePostazioniList(
      0,
      9999,
      'ASC',
      'id',
      this.form.get("dataDa").value,
      this.form.get("dataA").value,
    )]).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: [ResponseQueryByCriteria<PostazioneDTO>, PrenotazionePostazioneDTO[]]) =>{  
     
     res[1].forEach((prenotazione: PrenotazionePostazioneDTO) =>{
      let disc = "";
      if(this.mappaPostazioniRect.get(prenotazione.postazione.id)==null){
        if(prenotazione.prenotazionePerOspite){
          disc = "ospite";
          let currentRect: Rect = {
            id: prenotazione.postazione.id,
            height: prenotazione.postazione.punto2y - prenotazione.postazione.punto1y,
            width: prenotazione.postazione.punto2x - prenotazione.postazione.punto1x,
            top: prenotazione.postazione.punto1y,
            left: prenotazione.postazione.punto1x,
            discriminator : disc,
            isPrenotata: true,
            nome: 'Ospite di ' + prenotazione.richiedente?.nome,
            cognome: prenotazione.richiedente?.cognome
          };
          this.mappaPostazioniRect.set(prenotazione.postazione.id, currentRect);
        }else{
          disc = "collaboratore";
          let currentRect: Rect = {
            id: prenotazione.postazione.id,
            height: prenotazione.postazione.punto2y - prenotazione.postazione.punto1y,
            width: prenotazione.postazione.punto2x - prenotazione.postazione.punto1x,
            top: prenotazione.postazione.punto1y,
            left: prenotazione.postazione.punto1x,
            discriminator : disc,
            isPrenotata: true,
            nome: prenotazione.richiedente?.nome,
            cognome: prenotazione.richiedente?.cognome
          };
  
          this.mappaPostazioniRect.set(prenotazione.postazione.id, currentRect);
        }
        }
      })

      res[0].content.forEach((postazione: PostazioneDTO) =>{
        if(this.mappaPostazioniRect.get(postazione.id)==null){
          let currentRect: Rect = {
            id: postazione.id,
            height: postazione.punto2y - postazione.punto1y,
            width: postazione.punto2x - postazione.punto1x,
            top: postazione.punto1y,
            left: postazione.punto1x,
            discriminator : "libera",
            isPrenotata : false,
            nome: 'Postazione',
            cognome: 'libera'
          };
          this.mappaPostazioniRect.set(postazione.id, currentRect);
          this.filteredPostazioni.push(postazione);
          this.numeroPostazioniPrenotabiliInSede++;
          }
        })
        this.mapToArray();
    });
    }
      

  mapToArray(){
    this.postazioniRect = Array.from(this.mappaPostazioniRect.values());
  }

  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
    if (this.form.get("dataDa").valid && this.form.get("dataA").valid && this.form.get("sede").valid) {
      this.setPostazione();
    }
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
    if (this.form.get("dataDa").valid && this.form.get("dataA").valid && this.form.get("sede").valid) {
      this.setPostazione();
    }
  }

  prenotazionepostazioneSave() {
    if (this.prenotazionepostazioneId == null || this.prenotazionepostazioneId == 0) {
      this.prenotazionepostazioneCreate();
    } else {
      this.prenotazionepostazioneUpdate();
    }
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  checkPrenotabileForClick(isPrenotata:boolean, id:number){
    isPrenotata === false ? this.postazione(id) : this.snackBar.open("Attenzione questa prenotazione non è prenotabile!", null, { duration: 3000 });
  }

  postazione(post: number): void {
    this.postazioneService.read(post).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: PostazioneDTO) => {
        this.form.get("descrizione").setValue(res);
        this.form.markAsDirty();
      }
    );
  }

  prenotazionepostazioneUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la prenotazione della postazione?").pipe(
        takeUntil(this.destroy$)
      ).subscribe(result => {
        if (result) {
          this.formToDto();
          this.prenotazionepostazioneService.update(this.prenotazionepostazione).pipe(
            takeUntil(this.destroy$)
          ).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.prenotazionepostazioneRead();
              this.form.markAsPristine();
              this.backConfirm('/prenotazionepostazione');
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_REACHED_SEDE") {
                this.snackBar.open("Attenzione! Troppe postazioni occupate in sede per le date selezionate.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_REACHED_UFFICIO") {
                this.snackBar.open("Attenzione! Troppe postazioni occupate in ufficio per le date selezionate.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_RANGE") {
                this.snackBar.open("Attenzione! Impossibile prenotare così tanti giorni.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Hai già delle prenotazioni attive per il periodo indicato.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_POSTAZIONE_ALREADY_TOKEN") {
                this.snackBar.open("Attenzione! La postazione è già occupata per le date selezionate.", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  prenotazionepostazioneCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.prenotazionepostazioneService.create(this.prenotazionepostazione).pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (res: PrenotazionePostazioneDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.prenotazionepostazione = res;
          this.prenotazionepostazioneId = this.prenotazionepostazione.id;
          this.dtoToForm();
          this.form.markAsPristine();
          this.backConfirm('/prenotazionepostazione');
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_PASTPRENOTAZIONE") {
            this.snackBar.open("Attenzione! Impossibile prenotare una data passata.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_REACHED_SEDE") {
            this.snackBar.open("Attenzione! Troppe postazioni occupate in sede per le date selezionate.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_REACHED_UFFICIO") {
            this.snackBar.open("Attenzione! Troppe postazioni occupate in ufficio per le date selezionate.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_MAX_RANGE") {
            this.snackBar.open("Attenzione! Impossibile prenotare così tanti giorni.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Hai già delle prenotazioni attive per il periodo indicato.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazionePostazioneException_PRENOTAZIONEPOSTAZIONE_POSTAZIONE_ALREADY_TOKEN") {
            this.snackBar.open("Attenzione! La postazione è già occupata per le date selezionate.", null, { duration: 3000 });
          }
        }
      );
    }
  }

  prenotazionepostazioneDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la prenotazione della postazione?").pipe(
        takeUntil(this.destroy$)
      ).subscribe(result => {
        if (result) {
          this.formToDto();
          this.prenotazionepostazioneService.delete(this.prenotazionepostazione.id).pipe(
            takeUntil(this.destroy$)
          ).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/prenotazionepostazione"]);
            }
          );
        }
      });
    }
  }

  prenotazionepostazioneRead() {
    this.prenotazionepostazioneService.read(this.prenotazionepostazioneId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: PrenotazionePostazioneDTO) => {
        this.prenotazionepostazione = res;
        this.prenotazionepostazioneId = res.id;
        this.dtoToForm();
        this.fillMapOfRect();
      }
    );
  }

  isValidFormPostazione(): boolean {
    if (typeof this.form.get("descrizione").value === "string" || this.form.get("descrizione").value == null) {
      return false;
    }
    return true;
  }

  isValidFormFiltersSelected(): boolean {
    if (this.form.get("dataDa").valid && this.form.get("dataA").valid && this.form.get("sede").valid) {
      return false;
    }
    return true;
  }

  private setValueOfCheckBoxToFalseIfNull(){
    if(this.form.get("prenotazionePerOspite").value != true){
      return false;
    }else{
      return true;
    }
  }

  private formToDto(): void {
    this.prenotazionepostazione.postazione = this.form.get("descrizione").value;
    this.prenotazionepostazione.postazione.ufficio.sede = this.form.get("sede").value;
    this.prenotazionepostazione.dataDa = this.form.get("dataDa").value;
    this.prenotazionepostazione.dataA = this.form.get("dataA").value;
    this.prenotazionepostazione.prenotazionePerOspite = this.setValueOfCheckBoxToFalseIfNull();
    this.prenotazionepostazione.richiedente = this.form.get("richiedente").value;
    this.prenotazionepostazione.note = this.form.get("note").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.prenotazionepostazione.id);
    this.form.get("descrizione").setValue(this.prenotazionepostazione.postazione);
    this.form.get("sede").setValue(this.prenotazionepostazione.postazione.ufficio.sede);
    this.form.get("dataDa").setValue(this.prenotazionepostazione.dataDa);
    this.form.get("dataA").setValue(this.prenotazionepostazione.dataA);
    this.form.get("richiedente").setValue(this.prenotazionepostazione.richiedente);
    this.form.get("note").setValue(this.prenotazionepostazione.note);
    this.form.get("deleteDate").setValue(this.prenotazionepostazione.deleteDate);
    this.form.get("deleteUser").setValue(this.prenotazionepostazione.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.prenotazionepostazione.insertDate));
    this.form.get("insertUser").setValue(this.prenotazionepostazione.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.prenotazionepostazione.updateDate));
    this.form.get("updateUser").setValue(this.prenotazionepostazione.updateUser);
    this.form.get("optLock").setValue(this.prenotazionepostazione.optLock);
    this.form.get("prenotazionePerOspite").setValue(this.prenotazionepostazione.prenotazionePerOspite);
    this.form.markAsPristine();
  }


}
